import { MeetingPriority, MeetingStatus, Meetings } from '../..';
import { MEETING_STATUS_LABELS } from '../../../constants';

export const meetings: Meetings = {
  '1': {
    'id': 1,
    'external_id': '54464424',
    'leaders': [
      2
    ],
    'title': 'Sales Meeting',
    'title_booked': null,
    'calendar_tz': 'America/Denver',
    'secondary_tz': ['America/New_York'],
    'copy_tz': 'America/Denver',
    'secondary_copy_tz': null,
    'use_link': false,
    'duration_minutes': 30,
    'date_scheduled': null,
    'create_date': '2022-02-26T00:26:36.085083Z',
    'created_at': '2022-02-26T00:26:36.085083Z',
    'updated_at': null,
    'status': 1,
    'status_data': {
      'id': MeetingStatus.PENDING,
      'name': MEETING_STATUS_LABELS[MeetingStatus.PENDING]
    },
    'rooms': [],
    'location_presets': [],
    'locations': [''],
    'booking_calendar': 1,
    'conference_provider': 3,
    'conference_leader': null,
    'prevent_conflict': false,
    'is_poll': true,
    'description': 'Meeting with the East Coast sales team and leaders',
    'num_expected_participants': 7,
    'poll_selections': {},
    'event_start_time': null,
    'selected_scheduled_time': null,
    'event_id': null,
    'executive_hold': false,
    'hold_calendars': [],
    'errors': [],
    'error_count': 0,
    'show_voter_names': false,
    'allow_add_participants': true,
    'questions': [],
    'auto_merge_slots': true,
    'is_reusable': false,
    'enable_public_attendee_list': false,
    'allow_reschedule_cancel': false,
    'recurring_times': null,
    'priority': MeetingPriority.MEDIUM,
    'start_delay_minutes': 60,
    'slot_time_interval': null,
    'buffer_start_minutes': 0,
    'buffer_end_minutes': 0,
    'participants': {
      '1': {
        'id': 1,
        'meeting': 3,
        'name': 'Sara Francis',
        'email': 'democabinetlawrence@gmail.com',
        'email_hash': '0ae052552798ef14da3dc5b832ac7de24401741b',
        'first_response_date': '2022-02-26T00:35:17.761120Z',
        'required': true,
        'no_times_comment': null,
        'calendar_access': null,
        'prevent_conflicts': true,
        'view_calendar': false,
        'should_invite': false,
        'is_fetchable': false,
      },
      '2': {
        'id': 2,
        'meeting': 3,
        'name': 'Steve Green',
        'email': 'julia@gmail.com',
        'email_hash': '81cfc82ddf50585b7f86dfdac6df02c64d7b2d1f',
        'first_response_date': '2022-02-26T00:38:30.636685Z',
        'required': true,
        'no_times_comment': null,
        'calendar_access': null,
        'prevent_conflicts': true,
        'view_calendar': false,
        'should_invite': false,
        'is_fetchable': false,
      },
      '3': {
        'id': 3,
        'meeting': 3,
        'name': 'Jessica Parker',
        'email': 'john@gmail.com',
        'email_hash': '9025f6f4ea9e9609ab7cb6fb79e6ae83112be4f8',
        'first_response_date': '2022-02-26T00:39:39.846371Z',
        'required': true,
        'no_times_comment': null,
        'calendar_access': null,
        'prevent_conflicts': true,
        'view_calendar': false,
        'should_invite': false,
        'is_fetchable': false,
      },
      '4': {
        'id': 4,
        'meeting': 4,
        'name': 'Taylor Adams',
        'email': 'taylor@gmail.com',
        'email_hash': 'f268e68357e88e67086672d9056fd0bd8363f014',
        'first_response_date': '2022-02-26T00:41:21.443722Z',
        'required': true,
        'no_times_comment': null,
        'calendar_access': null,
        'prevent_conflicts': true,
        'view_calendar': false,
        'should_invite': false,
        'is_fetchable': false,
      }
    },
    files: [],
    send_participant_reminders: false,
    participant_reminder_minutes: 24 * 60,
  },
  '2': {
    'id': 2,
    'external_id': '69991352',
    'leaders': [
      2
    ],
    'title': 'Marketing Meeting',
    'title_booked': null,
    'calendar_tz': 'America/Denver',
    'secondary_tz': ['America/New_York'],
    'copy_tz': 'America/Denver',
    'secondary_copy_tz': null,
    'use_link': false,
    'duration_minutes': 45,
    'date_scheduled': null,
    'create_date': '2022-02-26T00:20:09.536397Z',
    'created_at': '2022-02-26T00:26:36.085083Z',
    'updated_at': null,
    'status': 1,
    'show_voter_names': false,
    'allow_add_participants': true,
    'status_data': {
      'id': MeetingStatus.PENDING,
      'name': MEETING_STATUS_LABELS[MeetingStatus.PENDING]
    },
    'enable_public_attendee_list': false,
    'rooms': [],
    'location_presets': [],
    'locations': [''],
    'booking_calendar': 1,
    'conference_provider': 3,
    'conference_leader': null,
    'prevent_conflict': true,
    'is_poll': true,
    'description': 'Marketing meeting with entire Marketing Team in Ohio division',
    'num_expected_participants': 3,
    'poll_selections': {},
    'event_start_time': null,
    'selected_scheduled_time': null,
    'event_id': null,
    'executive_hold': false,
    'hold_calendars': [],
    'errors': [],
    'questions': [],
    'auto_merge_slots': true,
    'is_reusable': false,
    'error_count': 0,
    'allow_reschedule_cancel': false,
    'recurring_times': null,
    'priority': MeetingPriority.MEDIUM,
    'start_delay_minutes': 60,
    'slot_time_interval': null,
    'buffer_start_minutes': 0,
    'buffer_end_minutes': 0,
    'participants': {
      '1': {
        'id': 1,
        'meeting': 3,
        'name': 'Sara Francis',
        'email': 'democabinetlawrence@gmail.com',
        'email_hash': '0ae052552798ef14da3dc5b832ac7de24401741b',
        'first_response_date': '2022-02-26T00:35:17.761120Z',
        'required': true,
        'no_times_comment': null,
        'calendar_access': null,
        'prevent_conflicts': true,
        'view_calendar': false,
        'should_invite': false,
        'is_fetchable': false,
      },
      '2': {
        'id': 2,
        'meeting': 3,
        'name': 'Steve Green',
        'email': 'julia@gmail.com',
        'email_hash': '81cfc82ddf50585b7f86dfdac6df02c64d7b2d1f',
        'first_response_date': '2022-02-26T00:38:30.636685Z',
        'required': true,
        'no_times_comment': null,
        'calendar_access': null,
        'prevent_conflicts': true,
        'view_calendar': false,
        'should_invite': false,
        'is_fetchable': false,
      }
    },
    files: [],
    send_participant_reminders: false,
    participant_reminder_minutes: 24 * 60,
  },
  '3': {
    'id': 3,
    'external_id': '55621257',
    'leaders': [
      2
    ],
    'title': 'Operations Meeting',
    'title_booked': null,
    'calendar_tz': 'America/Denver',
    'secondary_tz': ['America/New_York'],
    'copy_tz': 'America/Denver',
    'secondary_copy_tz': null,
    'use_link': false,
    'duration_minutes': 30,
    'date_scheduled': null,
    'create_date': '2022-02-26T00:08:04.705838Z',
    'created_at': '2022-02-26T00:26:36.085083Z',
    'updated_at': null,
    'status': 1,
    'status_data': {
      'id': MeetingStatus.PENDING,
      'name': MEETING_STATUS_LABELS[MeetingStatus.PENDING]
    },
    'enable_public_attendee_list': false,
    'rooms': [],
    'location_presets': [],
    'show_voter_names': false,
    'allow_add_participants': true,
    'locations': [''],
    'booking_calendar': 1,
    'conference_provider': 3,
    'conference_leader': null,
    'prevent_conflict': true,
    'is_poll': true,
    'description': 'Meeting to discuss any maintenance issues with all 3 locations.  COO and Manager of Operations.',
    'num_expected_participants': 4,
    'poll_selections': {},
    'event_start_time': null,
    'selected_scheduled_time': null,
    'event_id': null,
    'executive_hold': false,
    'hold_calendars': [],
    'errors': [],
    'questions': [],
    'auto_merge_slots': true,
    'is_reusable': false,
    'error_count': 0,
    'allow_reschedule_cancel': false,
    'recurring_times': null,
    'priority': MeetingPriority.MEDIUM,
    'start_delay_minutes': 60,
    'slot_time_interval': null,
    'buffer_start_minutes': 0,
    'buffer_end_minutes': 0,
    'participants': {
      '1': {
        'id': 1,
        'meeting': 3,
        'name': 'Sara Francis',
        'email': 'democabinetlawrence@gmail.com',
        'email_hash': '0ae052552798ef14da3dc5b832ac7de24401741b',
        'first_response_date': '2022-02-26T00:35:17.761120Z',
        'required': true,
        'no_times_comment': null,
        'calendar_access': null,
        'prevent_conflicts': true,
        'view_calendar': false,
        'should_invite': false,
        'is_fetchable': false,
      }
    },
    files: [],
    send_participant_reminders: false,
    participant_reminder_minutes: 24 * 60,
  },
  '4': {
    'id': 4,
    'show_voter_names': false,
    'allow_add_participants': true,
    'external_id': '40190595',
    'leaders': [
      2
    ],
    'title': 'Budget Planning Meeting',
    'title_booked': null,
    'calendar_tz': 'America/Denver',
    'secondary_tz': ['America/New_York'],
    'copy_tz': 'America/Denver',
    'secondary_copy_tz': null,
    'use_link': false,
    'duration_minutes': 60,
    'date_scheduled': null,
    'create_date': '2022-02-26T00:03:57.191690Z',
    'created_at': '2022-02-26T00:26:36.085083Z',
    'updated_at': null,
    'status': 1,
    'status_data': {
      'id': MeetingStatus.PENDING,
      'name': MEETING_STATUS_LABELS[MeetingStatus.PENDING]
    },
    'rooms': [],
    'location_presets': [],
    'locations': [''],
    'booking_calendar': 1,
    'conference_provider': 3,
    'conference_leader': null,
    'prevent_conflict': false,
    'is_poll': true,
    'description': 'CFO leads the meeting which includes all C-Suite',
    'num_expected_participants': 5,
    'poll_selections': {},
    'event_start_time': null,
    'selected_scheduled_time': null,
    'event_id': null,
    'executive_hold': false,
    'hold_calendars': [],
    'errors': [],
    'questions': [],
    'auto_merge_slots': true,
    'is_reusable': false,
    'error_count': 0,
    'enable_public_attendee_list': false,
    'allow_reschedule_cancel': false,
    'recurring_times': null,
    'priority': MeetingPriority.MEDIUM,
    'start_delay_minutes': 60,
    'slot_time_interval': null,
    'buffer_start_minutes': 0,
    'buffer_end_minutes': 0,
    'participants': {
      '1': {
        'id': 1,
        'meeting': 3,
        'name': 'Sara Francis',
        'email': 'democabinetlawrence@gmail.com',
        'email_hash': '0ae052552798ef14da3dc5b832ac7de24401741b',
        'first_response_date': '2022-02-26T00:35:17.761120Z',
        'required': true,
        'no_times_comment': null,
        'calendar_access': null,
        'prevent_conflicts': true,
        'view_calendar': false,
        'should_invite': false,
        'is_fetchable': false,
      },
      '2': {
        'id': 2,
        'meeting': 3,
        'name': 'Steve Green',
        'email': 'julia@gmail.com',
        'email_hash': '81cfc82ddf50585b7f86dfdac6df02c64d7b2d1f',
        'first_response_date': '2022-02-26T00:38:30.636685Z',
        'required': true,
        'no_times_comment': null,
        'calendar_access': null,
        'prevent_conflicts': true,
        'view_calendar': false,
        'should_invite': false,
        'is_fetchable': false,
      }
    },
    files: [],
    send_participant_reminders: false,
    participant_reminder_minutes: 24 * 60,
  },
  '5': {
    'id': 5,
    'show_voter_names': false,
    'allow_add_participants': true,
    'external_id': '62681119',
    'leaders': [
      2
    ],
    'title': 'Board Meeting',
    'title_booked': null,
    'calendar_tz': 'America/Denver',
    'secondary_tz': ['America/New_York'],
    'copy_tz': 'America/Denver',
    'secondary_copy_tz': null,
    'use_link': false,
    'duration_minutes': 90,
    'date_scheduled': null,
    'create_date': '2022-02-25T18:18:05.488627Z',
    'created_at': '2022-02-26T00:26:36.085083Z',
    'updated_at': null,
    'status': 2,
    'status_data': {
      'id': MeetingStatus.SCHEDULED,
      'name': MEETING_STATUS_LABELS[MeetingStatus.SCHEDULED]
    },
    'locations': [''],
    'booking_calendar': 1,
    'conference_provider': 3,
    'conference_leader': null,
    'prevent_conflict': false,
    'is_poll': true,
    'description': 'Annual Board Meeting',
    'num_expected_participants': 8,
    'poll_selections': {},
    'event_start_time': null,
    'selected_scheduled_time': null,
    'event_id': null,
    'executive_hold': false,
    'hold_calendars': [],
    'errors': [],
    'questions': [],
    'auto_merge_slots': true,
    'is_reusable': false,
    'error_count': 0,
    'rooms': [],
    'location_presets': [],
    'enable_public_attendee_list': false,
    'allow_reschedule_cancel': false,
    'recurring_times': null,
    'priority': MeetingPriority.MEDIUM,
    'start_delay_minutes': 60,
    'slot_time_interval': null,
    'buffer_start_minutes': 0,
    'buffer_end_minutes': 0,
    'participants': {
      '1': {
        'id': 1,
        'meeting': 3,
        'name': 'Sara Francis',
        'email': 'democabinetlawrence@gmail.com',
        'email_hash': '0ae052552798ef14da3dc5b832ac7de24401741b',
        'first_response_date': '2022-02-26T00:35:17.761120Z',
        'required': true,
        'no_times_comment': null,
        'calendar_access': null,
        'prevent_conflicts': true,
        'view_calendar': false,
        'should_invite': false,
        'is_fetchable': false,
      },
      '2': {
        'id': 2,
        'meeting': 3,
        'name': 'Steve Green',
        'email': 'julia@gmail.com',
        'email_hash': '81cfc82ddf50585b7f86dfdac6df02c64d7b2d1f',
        'first_response_date': '2022-02-26T00:38:30.636685Z',
        'required': true,
        'no_times_comment': null,
        'calendar_access': null,
        'prevent_conflicts': true,
        'view_calendar': false,
        'should_invite': false,
        'is_fetchable': false,
      },
      '3': {
        'id': 3,
        'meeting': 3,
        'name': 'Jessica Parker',
        'email': 'john@gmail.com',
        'email_hash': '9025f6f4ea9e9609ab7cb6fb79e6ae83112be4f8',
        'first_response_date': '2022-02-26T00:39:39.846371Z',
        'required': true,
        'no_times_comment': null,
        'calendar_access': null,
        'prevent_conflicts': true,
        'view_calendar': false,
        'should_invite': false,
        'is_fetchable': false,
      },
      '4': {
        'id': 4,
        'meeting': 4,
        'name': 'Taylor Adams',
        'email': 'taylor@gmail.com',
        'email_hash': 'f268e68357e88e67086672d9056fd0bd8363f014',
        'first_response_date': '2022-02-26T00:41:21.443722Z',
        'required': true,
        'no_times_comment': null,
        'calendar_access': null,
        'prevent_conflicts': true,
        'view_calendar': false,
        'should_invite': false,
        'is_fetchable': false,
      },
      '5': {
        'id': 5,
        'meeting': 5,
        'name': 'David Ricky',
        'email': 'david@gmail.com',
        'email_hash': 'ddc2e2f714d4e92ba63e1e6dd82ada367ef4f54c',
        'first_response_date': '2022-02-26T00:42:35.719118Z',
        'required': true,
        'no_times_comment': null,
        'calendar_access': null,
        'prevent_conflicts': true,
        'view_calendar': false,
        'should_invite': false,
        'is_fetchable': false,
      }
    },
    files: [],
    send_participant_reminders: false,
    participant_reminder_minutes: 24 * 60,
  }
};